<template>
  <div class="page w-full h-100">

    <div class="flex all items-center">

      <div class="text-c36 font-bold w-full text-center text-black">
        <div class="flex justify-center">
          <img src="../../assets/images/png/logo-oremi.png" alt="" >
        </div>

        <div>En cours de maintenance ...</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: { },
  name: 'index-TermsOfService.vue',
  data () {
    return {
    }
  }
}
</script>

<style scoped>
.all {
  height: 100vh;
}
</style>
